import Margins from "@components/Margins/Margins";
import React, { FC, ReactNode } from "react";

import { RequestDemoButtonV2 } from "@components/Button_new/Button";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./LogosAndG2Section.module.scss";

export const LogosAndG2Section = () => {
	return (
		<Margins className={styles.marginSectionWithLogos}>
			<div style={{maxWidth:"1400px", margin:'auto'}}>
			<div className={styles.gridContainer}>
				<div className={styles.letsTalk}>
					<div className={styles.title}>Let’s Talk</div>
					<div className={styles.content}>
						Connect with an expert to see if Genemod is right for
						you.
					</div>
					<RequestDemoButtonV2
						mobileFullWidth={false}
						linkClassName={styles.requestDemoButtonV2Container}
					/>
				</div>
				<div className={styles.image}>
					<StaticImage
						loading="eager"
						src={"./_assets/LetsTalk.png"}
						alt="solution-g2"
					/>
				</div>
			</div>
			</div>
			
		</Margins>
	);
};
