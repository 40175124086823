import { PageLayout } from "@components/index";
import React from "react";
import * as styles from "./index.module.scss";
import { Hero } from "@components/LimsSoftware/Hero";
import GENEMOD_FREEZER from "@assets/products/genemod_ELN_v6.mp4";
import { Colors } from "@helpers/Colors";
import { ELN } from "@components/ElnSoftware/eln";
import { CentralProtocolHub } from "@components/ElnSoftware/CentralProtocolHub";
import { LiveEditing } from "@components/ElnSoftware/LiveEditing";
import { ComprehensivePM } from "@components/ElnSoftware/ComprehensivePM";
import { ModernELN } from "@components/ElnSoftware/ModernELN";
import { Industries } from "pages/ads/_components/_Industries/_Industries";
import { SectionLearnMore } from "@components/ElnSoftware/SectionLearnMore";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

type HeroProps = {
	title: string;
	sub_title: string;
	video_src: string;
	text: string;
	containerOverwrite?: string;
	heroWrapperOverride?: string;
	autoplay: boolean;
	bodyColor?: Colors;
};

export default function ELNSoftwarePage() {
	const heroProps: HeroProps = {
		sub_title: "Products",
		title: "Electronic Lab Notebook (ELN)",
		video_src: GENEMOD_FREEZER,
		text: "Transform your laboratory’s productivity with Genemod’s Electronic Lab Notebook (ELN). Improve the management of experiments, research data, protocols, and all the essential lab documentation.",
		containerOverwrite: styles.heroContainer,
		heroWrapperOverride: styles.heroWrapperOverride,
		autoplay: true,
		bodyColor:'text-secondary'
	};
	const industriesSectionProps = {
		tabsGap: styles.tabsGap,
		industriesContainerOverride: styles.industriesContainerOverride,
		industriesWrapper: styles.industriesWrapper,
		overrideFirstImage: styles.overrideFirstImage,
		overrideSecondImage: styles.overrideSecondImage,
		overrideImagesCard: styles.overrideImagesCard,
		justTitleAndContentOverwrite: styles.justTitleAndContentOverwrite,
		rightSectionOverride: styles.rightSectionOverride,
		tabsOverride:styles.tabsOverride
	};

	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "eln-software" }}
			defaultHeader="light"
		>
			<div className={styles.pageWrapper}>
				<Hero {...heroProps} />
				<ELN />
				<CentralProtocolHub />
				<LiveEditing />
				<LogosAndG2Section />
				<ComprehensivePM />
				<ModernELN />
				<Industries {...industriesSectionProps} />
				<SectionLearnMore />
			</div>
		</PageLayout>
	);
}
