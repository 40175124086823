// extracted by mini-css-extract-plugin
export var BODY_TEXT_EXTRA_LARGE = "Paginator-module--BODY_TEXT_EXTRA_LARGE--4cBXK";
export var BODY_TEXT_EXTRA_SMALL = "Paginator-module--BODY_TEXT_EXTRA_SMALL--jLtd6";
export var BODY_TEXT_LARGE = "Paginator-module--BODY_TEXT_LARGE--fMopH";
export var BODY_TEXT_LARGE_V2 = "Paginator-module--BODY_TEXT_LARGE_V2--3h0LL";
export var BODY_TEXT_MEDIUM = "Paginator-module--BODY_TEXT_MEDIUM--4C77o";
export var BODY_TEXT_MEDIUM_V1 = "Paginator-module--BODY_TEXT_MEDIUM_V1--yt3y-";
export var BODY_TEXT_SMALL = "Paginator-module--BODY_TEXT_SMALL--qRd-Y";
export var BOLD = "Paginator-module--BOLD--oaGBJ";
export var COMMON = "Paginator-module--COMMON--hHp3-";
export var HEADING = "Paginator-module--HEADING--Nb95j";
export var HEADING_1 = "Paginator-module--HEADING_1--0N-oA";
export var HEADING_1_V1 = "Paginator-module--HEADING_1_V1--0OnEf";
export var HEADING_2 = "Paginator-module--HEADING_2--+OWsT";
export var HEADING_2_V2 = "Paginator-module--HEADING_2_V2--AcDJh";
export var HEADING_3 = "Paginator-module--HEADING_3--8h7zY";
export var HEADING_4 = "Paginator-module--HEADING_4--n-ynV";
export var HEADING_5 = "Paginator-module--HEADING_5--4o7dY";
export var HEADING_5_V2 = "Paginator-module--HEADING_5_V2--8T0LK";
export var MEDIUM = "Paginator-module--MEDIUM--ZlRTS";
export var MOBILE_BODY_TEXT_EXTRA_LARGE = "Paginator-module--MOBILE_BODY_TEXT_EXTRA_LARGE--F1TtI";
export var MOBILE_BODY_TEXT_EXTRA_SMALL = "Paginator-module--MOBILE_BODY_TEXT_EXTRA_SMALL--lRpBY";
export var MOBILE_BODY_TEXT_LARGE = "Paginator-module--MOBILE_BODY_TEXT_LARGE--IJ6pq";
export var MOBILE_BODY_TEXT_LARGE_V2 = "Paginator-module--MOBILE_BODY_TEXT_LARGE_V2--BHR0F";
export var MOBILE_BODY_TEXT_MEDIUM = "Paginator-module--MOBILE_BODY_TEXT_MEDIUM--GM6qX";
export var MOBILE_BODY_TEXT_MEDIUM_V1 = "Paginator-module--MOBILE_BODY_TEXT_MEDIUM_V1--Vj-tO";
export var MOBILE_BODY_TEXT_SMALL = "Paginator-module--MOBILE_BODY_TEXT_SMALL--JnGam";
export var MOBILE_HEADING_1 = "Paginator-module--MOBILE_HEADING_1--ZwSTq";
export var MOBILE_HEADING_1_V1 = "Paginator-module--MOBILE_HEADING_1_V1--f7vIQ";
export var MOBILE_HEADING_2 = "Paginator-module--MOBILE_HEADING_2--eZghz";
export var MOBILE_HEADING_2_V2 = "Paginator-module--MOBILE_HEADING_2_V2--oMK7C";
export var MOBILE_HEADING_3 = "Paginator-module--MOBILE_HEADING_3--gTJXk";
export var MOBILE_HEADING_4 = "Paginator-module--MOBILE_HEADING_4--uzDa3";
export var MOBILE_HEADING_5 = "Paginator-module--MOBILE_HEADING_5--9i3vJ";
export var MOBILE_HEADING_5_V2 = "Paginator-module--MOBILE_HEADING_5_V2--RWDnL";
export var REGULAR = "Paginator-module--REGULAR--k8sGB";
export var SEMIBOLD = "Paginator-module--SEMIBOLD--HKMEC";
export var TABLET_BODY_TEXT_EXTRA_LARGE = "Paginator-module--TABLET_BODY_TEXT_EXTRA_LARGE--Bx+lR";
export var TABLET_BODY_TEXT_EXTRA_SMALL = "Paginator-module--TABLET_BODY_TEXT_EXTRA_SMALL--xFQ75";
export var TABLET_BODY_TEXT_LARGE = "Paginator-module--TABLET_BODY_TEXT_LARGE--WtSua";
export var TABLET_BODY_TEXT_LARGE_V2 = "Paginator-module--TABLET_BODY_TEXT_LARGE_V2--C06UR";
export var TABLET_BODY_TEXT_MEDIUM = "Paginator-module--TABLET_BODY_TEXT_MEDIUM--iidoh";
export var TABLET_BODY_TEXT_MEDIUM_V1 = "Paginator-module--TABLET_BODY_TEXT_MEDIUM_V1--oUc9C";
export var TABLET_BODY_TEXT_SMALL = "Paginator-module--TABLET_BODY_TEXT_SMALL--0FaGg";
export var TABLET_HEADING_1 = "Paginator-module--TABLET_HEADING_1--q-YRM";
export var TABLET_HEADING_1_V1 = "Paginator-module--TABLET_HEADING_1_V1--Z6ygg";
export var TABLET_HEADING_2 = "Paginator-module--TABLET_HEADING_2--XhkWT";
export var TABLET_HEADING_2_V2 = "Paginator-module--TABLET_HEADING_2_V2--Og7T9";
export var TABLET_HEADING_3 = "Paginator-module--TABLET_HEADING_3--u3APn";
export var TABLET_HEADING_4 = "Paginator-module--TABLET_HEADING_4--civee";
export var TABLET_HEADING_5 = "Paginator-module--TABLET_HEADING_5--EMbKg";
export var TABLET_HEADING_5_V2 = "Paginator-module--TABLET_HEADING_5_V2--WMhr6";
export var arrowButton = "Paginator-module--arrowButton--Wkya3";
export var center = "Paginator-module--center--KArss";
export var disabled = "Paginator-module--disabled--nbz9F";
export var left = "Paginator-module--left--jSThe";
export var limitWidthOnLargeScreens = "Paginator-module--limitWidthOnLargeScreens--PAT4n";
export var paginator = "Paginator-module--paginator--GohFx";
export var right = "Paginator-module--right--nsij2";