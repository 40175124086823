import React, { useRef } from "react";
import * as styles from "./HeroV3.module.scss";
import SLM from "@assets/products/landing/slm.mp4";
import Margins from "@components/Margins/Margins";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { RequestDemoButtonV2 } from "@components/Button_new/Button";
import { Link } from "@components/index";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { PartnersV2 } from "pages/landingpagev2/_components/_Partners/_Partners";

export default function HeroV3() {
     const videoRef = useRef<HTMLVideoElement>(null);

    return (
        <section className={styles.sectionWrapper}>
            <Margins className={styles.sectionMargins}>
                <div className={styles.sectionContainer}>
                    <div className={styles.layout}>
                        <div className={styles.content}>
                            <TypographyV2
                                color="text-color"
                                variant="HEADING_1_V1"
                                weight="BOLD"
                                className={styles.title}
                            >
                                Simplify{" "}
                                <TypographyV2
                                    color="brand-primary-2"
                                    variant="HEADING_1_V1"
                                    weight="BOLD"
                                >
                                    Laboratory
                                </TypographyV2>{" "}
                                Management.
                            </TypographyV2>
                            <TypographyV2
                                variant="BODY_TEXT_MEDIUM_V1"
                                color="text-color-075"
                                className={classNames(
                                    styles.description,
                                    styles.largeScreen
                                )}
                            >
                                Cloud-based LIMS and ELN software to simplify
                                lab management and drive scientific research.
                                Tailored for Biotech R&D, with customizable
                                features to fit your lab’s specific
                                requirements.
                            </TypographyV2>
                            <div
                                className={classNames(
                                    styles.actions,
                                    styles.largeScreen
                                )}
                            >
                                <div style={{ width: "fit-content" }}>
                                    <RequestDemoButtonV2 variant="v2" />
                                </div>
                                <Link link="SIGN_UP">
                                    <TypographyV2
                                        variant="BODY_TEXT_MEDIUM"
                                        weight="MEDIUM"
                                        color="brand-black"
                                        className={styles.signUpAction}
                                    >
                                        Sign up free &gt;
                                    </TypographyV2>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div className={styles.videoWrapper}>
                                 <video
                                ref={videoRef}
                                className={styles.videoContainer}
                                muted
                                autoPlay
                                loop
                                playsInline
                                preload="auto"
                            >
                                <source
                                    src={SLM}
                                    type="video/mp4"
                                />
                            </video> 
                            </div>
                            <TypographyV2
                                variant="BODY_TEXT_MEDIUM_V1"
                                color="text-color-075"
                                className={classNames(
                                    styles.description,
                                    styles.smallScreen
                                )}
                            >
                                Cloud-based LIMS and ELN software to simplify
                                lab management and drive scientific research.
                                Tailored for Biotech R&D, with customizable
                                features to fit your lab’s specific
                                requirements.
                            </TypographyV2>
                            <div
                                className={classNames(
                                    styles.actions,
                                    styles.smallScreen
                                )}
                            >
                                <div style={{ width: "fit-content" }}>
                                    <RequestDemoButtonV2 variant="v2" />
                                </div>
                                <Link link="SIGN_UP">
                                    <TypographyV2
                                        variant="BODY_TEXT_MEDIUM"
                                        weight="MEDIUM"
                                        color="brand-black"
                                        className={styles.signUpAction}
                                    >
                                        Sign up free &gt;
                                    </TypographyV2>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Margins>
            <PartnersV2 hideTitle variant="v2" />
        </section>
    );
}

