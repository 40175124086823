// extracted by mini-css-extract-plugin
export var BODY = "index-module--BODY--rS6OS";
export var BODY_TEXT_EXTRA_LARGE = "index-module--BODY_TEXT_EXTRA_LARGE--ojmcQ";
export var BODY_TEXT_EXTRA_SMALL = "index-module--BODY_TEXT_EXTRA_SMALL--KXduI";
export var BODY_TEXT_LARGE = "index-module--BODY_TEXT_LARGE--rfidc";
export var BODY_TEXT_LARGE_V2 = "index-module--BODY_TEXT_LARGE_V2--t5K+9";
export var BODY_TEXT_MEDIUM = "index-module--BODY_TEXT_MEDIUM--xkF0V";
export var BODY_TEXT_MEDIUM_V1 = "index-module--BODY_TEXT_MEDIUM_V1--N4paq";
export var BODY_TEXT_SMALL = "index-module--BODY_TEXT_SMALL---hO61";
export var BODY__newLayout = "index-module--BODY__newLayout--MxCEK";
export var BOLD = "index-module--BOLD--g5U-2";
export var BUTTON = "index-module--BUTTON--jZ5eY";
export var BUTTON__newLayout = "index-module--BUTTON__newLayout--UdFnn";
export var CAPTION = "index-module--CAPTION--DfXLh";
export var CAPTION__newLayout = "index-module--CAPTION__newLayout--YnLmM";
export var COMMON = "index-module--COMMON--CT+-X";
export var DESCRIPTION = "index-module--DESCRIPTION--itMkj";
export var DESCRIPTION__newLayout = "index-module--DESCRIPTION__newLayout--3XZ-F";
export var FOOTER = "index-module--FOOTER--Kfv5x";
export var FOOTERBOLD = "index-module--FOOTERBOLD--cX23w";
export var FOOTER__newLayout = "index-module--FOOTER__newLayout--3Fx0t";
export var HEADER1 = "index-module--HEADER1--Xc2o6";
export var HEADER1__newLayout = "index-module--HEADER1__newLayout--8sESD";
export var HEADER2 = "index-module--HEADER2--n3WZl";
export var HEADER2__newLayout = "index-module--HEADER2__newLayout--eeKBB";
export var HEADER3 = "index-module--HEADER3--yGDYr";
export var HEADER3__newLayout = "index-module--HEADER3__newLayout--efhCU";
export var HEADER4 = "index-module--HEADER4--i0utE";
export var HEADER4__newLayout = "index-module--HEADER4__newLayout--agSDm";
export var HEADING = "index-module--HEADING--HOaFT";
export var HEADING_1 = "index-module--HEADING_1--IKuyi";
export var HEADING_1_V1 = "index-module--HEADING_1_V1--om8Ps";
export var HEADING_2 = "index-module--HEADING_2--Ul9ET";
export var HEADING_2_V2 = "index-module--HEADING_2_V2--QVkoW";
export var HEADING_3 = "index-module--HEADING_3--1gdD1";
export var HEADING_4 = "index-module--HEADING_4--dOk7N";
export var HEADING_5 = "index-module--HEADING_5--uJMO2";
export var HEADING_5_V2 = "index-module--HEADING_5_V2--TLqn-";
export var HERO = "index-module--HERO---2wK-";
export var HERO__newLayout = "index-module--HERO__newLayout--uhz7W";
export var MEDIUM = "index-module--MEDIUM--DEyDt";
export var MOBILE_BODY_TEXT_EXTRA_LARGE = "index-module--MOBILE_BODY_TEXT_EXTRA_LARGE--bp+u8";
export var MOBILE_BODY_TEXT_EXTRA_SMALL = "index-module--MOBILE_BODY_TEXT_EXTRA_SMALL--GQ56a";
export var MOBILE_BODY_TEXT_LARGE = "index-module--MOBILE_BODY_TEXT_LARGE--xTLn8";
export var MOBILE_BODY_TEXT_LARGE_V2 = "index-module--MOBILE_BODY_TEXT_LARGE_V2--7oBVK";
export var MOBILE_BODY_TEXT_MEDIUM = "index-module--MOBILE_BODY_TEXT_MEDIUM--zrlqH";
export var MOBILE_BODY_TEXT_MEDIUM_V1 = "index-module--MOBILE_BODY_TEXT_MEDIUM_V1--m9FhK";
export var MOBILE_BODY_TEXT_SMALL = "index-module--MOBILE_BODY_TEXT_SMALL--8IBmS";
export var MOBILE_HEADING_1 = "index-module--MOBILE_HEADING_1--W6TFQ";
export var MOBILE_HEADING_1_V1 = "index-module--MOBILE_HEADING_1_V1--Revko";
export var MOBILE_HEADING_2 = "index-module--MOBILE_HEADING_2--aAv3A";
export var MOBILE_HEADING_2_V2 = "index-module--MOBILE_HEADING_2_V2--RwMgj";
export var MOBILE_HEADING_3 = "index-module--MOBILE_HEADING_3--l7vcQ";
export var MOBILE_HEADING_4 = "index-module--MOBILE_HEADING_4--LgQBT";
export var MOBILE_HEADING_5 = "index-module--MOBILE_HEADING_5--nf6Qe";
export var MOBILE_HEADING_5_V2 = "index-module--MOBILE_HEADING_5_V2--0-yRq";
export var QUOTE = "index-module--QUOTE--GGQ2H";
export var REGULAR = "index-module--REGULAR--8fv91";
export var SEMIBOLD = "index-module--SEMIBOLD--h9Qpl";
export var SUBHEADERDESC = "index-module--SUBHEADERDESC--ZiYZ6";
export var SUBHEADERDESCBOLD = "index-module--SUBHEADERDESCBOLD--SnHBr";
export var SUBHEADERDESC__newLayout = "index-module--SUBHEADERDESC__newLayout--nr788";
export var SUBTITLE = "index-module--SUBTITLE--POcrG";
export var SUBTITLE__newLayout = "index-module--SUBTITLE__newLayout--w0jm5";
export var TABLET_BODY_TEXT_EXTRA_LARGE = "index-module--TABLET_BODY_TEXT_EXTRA_LARGE--Mn8Va";
export var TABLET_BODY_TEXT_EXTRA_SMALL = "index-module--TABLET_BODY_TEXT_EXTRA_SMALL--ncB+4";
export var TABLET_BODY_TEXT_LARGE = "index-module--TABLET_BODY_TEXT_LARGE--twkuy";
export var TABLET_BODY_TEXT_LARGE_V2 = "index-module--TABLET_BODY_TEXT_LARGE_V2--g0rhi";
export var TABLET_BODY_TEXT_MEDIUM = "index-module--TABLET_BODY_TEXT_MEDIUM--FTVhG";
export var TABLET_BODY_TEXT_MEDIUM_V1 = "index-module--TABLET_BODY_TEXT_MEDIUM_V1--UhLPR";
export var TABLET_BODY_TEXT_SMALL = "index-module--TABLET_BODY_TEXT_SMALL--Q1RJw";
export var TABLET_HEADING_1 = "index-module--TABLET_HEADING_1--HouqW";
export var TABLET_HEADING_1_V1 = "index-module--TABLET_HEADING_1_V1--03OVj";
export var TABLET_HEADING_2 = "index-module--TABLET_HEADING_2--LKY7B";
export var TABLET_HEADING_2_V2 = "index-module--TABLET_HEADING_2_V2--ik0fE";
export var TABLET_HEADING_3 = "index-module--TABLET_HEADING_3--EOolx";
export var TABLET_HEADING_4 = "index-module--TABLET_HEADING_4--FgGBO";
export var TABLET_HEADING_5 = "index-module--TABLET_HEADING_5--nj3VV";
export var TABLET_HEADING_5_V2 = "index-module--TABLET_HEADING_5_V2--6I4eX";
export var center = "index-module--center--nMWdo";
export var container = "index-module--container--snUs9";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--fMOPZ";
export var tocLinks = "index-module--tocLinks--hjmmK";