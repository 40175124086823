import React, { FunctionComponent } from "react";
import classNames from "classnames";
import * as styles from "./_Industries.module.scss";
import { LearnMoreButtonV3 } from "@components/Button_new/Button";
import { TabsV3, TypographyV2 } from "@components";
import { highlightedText } from "@components/TypographyV2/TypographyV2";
import {
	IndustrialBiotech,
	IndustrialBiotech2,
	Biorepository,
	Biorepository2,
	ClinicalResearch,
	ClinicalResearch2,
	Biomanufacturing,
	Biomanufacturing2,
	ContractServices,
	ContractServices2,
	BiopharmaceuticalRD1,
	BiopharmaceuticalRD,
} from "@assets/images/landing_page/index";
import { mapToKeyValue } from "@helpers/Hooks";

const TAB_CONTENT = [
	{
		title: "Biopharmaceutical",
		subtitle: highlightedText`Enhanced solutions for ${"biopharma"} R&D`,
		content:
			"Empower biopharma research with Genemod’s electronic lab notebook, designed to streamline data recording, organization, and collaboration. Our ELN enables efficient capture of experimental protocols and results, supports real-time team updates, and ensures all data is securely stored and easily accessible to drive faster, well-informed R&D decisions.",
		image2: <BiopharmaceuticalRD />,
		image: <BiopharmaceuticalRD1 />,
		badges: ["Drug Discovery", "Therapeutic R&D"],
		to: "BIOPHARMA",

		CTAText:"For innovations"
	},
	{
		title: "Industrial biotech",
		subtitle: highlightedText`Unified platform for ${"industrial biotech"} research`,
		content:
			"Advance your industrial biotech research with an ELN that enables meticulous documentation, sample traceability, and streamlined workflow management. Genemod’s ELN provides features to support strain design, process optimization, and data integrity, ensuring every experiment is tracked and accessible across teams for a holistic approach to research.",
		image: <IndustrialBiotech />,
		image2: <IndustrialBiotech2 />,
		badges: ["Bio-Based Innovations", "Bio-economy Advancements"],
		to: "BIOTECH",
		CTAText:"Scale your biotech"
	},
	{
		title: "Biorepository",
		subtitle: highlightedText`Optimized management for ${"biorepository"}`,
		content:
			"Simplify biorepository management with an ELN designed for seamless tracking and retrieval of specimens. Genemod’s ELN offers intuitive features for visualizing storage locations, logging sample metadata, and maintaining organized, searchable records. This approach enables efficient data handling and collaboration, crucial for effective biorepository operations.",
		image: <Biorepository />,
		image2: <Biorepository2 />,
		badges: ["Specimen Management & Tracking", "Advanced Sample Storage"],
		to: "REPOSITORY",
		CTAText:"Optimize process"
	},
	{
		title: "Clinical research",
		subtitle: highlightedText`Efficient data documentation for ${"clinical research"}`,
		content:
			"Genemod’s ELN enhances clinical research processes by enabling structured data capture from study design through patient data collection. With tools for documenting patient information, protocol adherence, and trial outcomes, the ELN supports accurate data analysis, ensuring compliance and expediting insights for more informed clinical decisions.",
		image: <ClinicalResearch />,
		image2: <ClinicalResearch2 />,
		badges: ["Biomedical Research", "Clinical Trial Innovation"],
		to: "RESEARCH",
		CTAText:"Streamline research"
	},
	{
		title: "Biomanufacturing",
		subtitle: highlightedText`Precision documentation in ${"biomanufacturing"}`,
		content:
			"Our ELN meets the rigorous demands of biomanufacturing with comprehensive documentation tools. From batch records to quality control logs, Genemod’s ELN allows precise tracking of production workflows, maintaining compliance with industry standards and enabling reliable, scalable manufacturing processes.",
		image: <Biomanufacturing />,
		image2: <Biomanufacturing2 />,
		badges: ["Bioprocess Optimization", "Pharmaceutical Production"],
		to: "MANUFACTURING",
		CTAText:"Enhance quality"
	},
	{
		title: "Contract services",
		subtitle: highlightedText`Streamlined documentation for  ${"contract research services"}`,
		content:
			"For contract research organizations, Genemod’s ELN centralizes project data, tracking every stage from initial planning to final reporting. Our ELN supports seamless sample management, project tracking, and collaborative documentation, allowing contract services to maintain comprehensive records and deliver reliable, reproducible results for clients.",
		image: <ContractServices />,
		image2: <ContractServices2 />,
		badges: ["CRO & CDMO", "Pharma Collaborations"],
		to: "SERVICES",
		CTAText:"Custom solutions"
	},
] as const;
const INDUSTRIES_TABS = TAB_CONTENT.map((tab) => tab.title);
type IndustriesTabs = typeof TAB_CONTENT[number]["title"];

type IndustriesProps = {
	tabsGap?: string;
	industriesWrapper?: string;
	overrideFirstImage?: string;
	overrideSecondImage?: string;
	overrideImagesCard?: string;
	justTitleAndContentOverwrite?: string;
	rightSectionOverride?:string;
	industriesContainerOverride?:string;
	tabsOverride?:string;
};

export const Industries: FunctionComponent<IndustriesProps> = ({
	tabsGap,
	industriesWrapper,
	overrideFirstImage,
	overrideSecondImage,
	overrideImagesCard,
	justTitleAndContentOverwrite,
	rightSectionOverride,
	industriesContainerOverride,
	tabsOverride
}) => {
	const [selectedTab, setSelectedTab] =
		React.useState<IndustriesTabs>("Biopharmaceutical");

	const { title, subtitle, content, image, image2, badges, to, CTAText } =
		mapToKeyValue(TAB_CONTENT, "title")[selectedTab];

	const tabsComp = (
		<TabsV3
			tabs={INDUSTRIES_TABS}
			selectedTab={selectedTab}
			onChange={setSelectedTab}
			wrapperClass={classNames(styles.tabs, tabsOverride)}
			tabsClassName={styles.tabElements}
			tabsGap={tabsGap}
		/>
	);

	return (
		<div className={classNames(styles.industriesContainer, industriesContainerOverride)}>
			<div className={classNames(styles.industries, industriesWrapper)}>
				<div className={styles.tabAndContent}>
					{tabsComp}
					<div className={styles.contentImageContainer}>
						<div className={styles.imagesContainer}>
							<div
								className={classNames(
									styles.imagesCard,
									overrideImagesCard
								)}
							>
								<div
									className={classNames(
										styles.firstImage,
										styles.image,
										overrideFirstImage
									)}
								>
									<div className={styles.orangeBorder}></div>
									{image2}
								</div>
								<div
									className={classNames(
										styles.secondImage,
										styles.image,
										overrideSecondImage
									)}
								>
									{image}
									<div className={styles.orangeBorder}></div>
								</div>
							</div>
						</div>
						<div className={styles.titleAndContentContainer}>
							<div className={styles.badgesMobile}>
								{badges.map((badge, index) => {
									return (
										<div
											className={styles.badge}
											key={index}
										>
											<TypographyV2
												color="text-tertiary-v2"
												variant="BODY_TEXT_MEDIUM"
												tabletVariant="BODY_TEXT_MEDIUM"
												mobileVariant="BODY_TEXT_MEDIUM"
												weight="REGULAR"
												style={{ margin: 0 }}
											>
												#{badge}
											</TypographyV2>
										</div>
									);
								})}
							</div>
							<div className={classNames(styles.titleAndContent, rightSectionOverride)}>
								<div
									className={classNames(
										styles.justTitleAndContent,
										justTitleAndContentOverwrite
									)}
								>
									<TypographyV2
										variant="HEADING_4"
										tabletVariant="HEADING_4"
										mobileVariant="HEADING_4"
										weight="MEDIUM"
										color="brand-500"
										className={styles.title}
									>
										{subtitle}
									</TypographyV2>
									<TypographyV2
										variant="BODY_TEXT_LARGE"
										tabletVariant="BODY_TEXT_LARGE"
										mobileVariant="BODY_TEXT_LARGE"
										weight="REGULAR"
										color="text-tertiary-v2"
										className={styles.content}
									>
										{content}
									</TypographyV2>
								</div>
								<div
									className={styles.badgeAndLearnMoreSection}
								>
									<div className={styles.badges}>
										{badges.map((badge, index) => {
											return (
												<div
													className={styles.badge}
													key={index}
												>
													<TypographyV2
														color="text-tertiary-v2"
														variant="BODY_TEXT_MEDIUM"
														tabletVariant="BODY_TEXT_MEDIUM"
														mobileVariant="BODY_TEXT_MEDIUM"
														weight="REGULAR"
														style={{ margin: 0 }}
													>
														#{badge}
													</TypographyV2>
												</div>
											);
										})}
									</div>
									<div className={styles.learnMoreButton}>
										<LearnMoreButtonV3
											to={to}
											text={CTAText}
											textClassName={
												styles.learnMoreButtonText
											}
											className={
												styles.learnMoreButtonContainer
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
